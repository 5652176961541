@import "../common/colors.scss";
@import "../common/fontSizes.scss";
@import "../common/utilities.scss";

html.uiv3 {
	.modernLifeSimplifiedView {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: 0;
		gap: 2.4rem;
		// width: 42rem;

		.sidePromo {
			width: 70rem;
			height: auto;
			margin-left: 5rem;

			.illustration {
				width: 100%;
				height: auto;
				object-fit: cover;
			}
		}

		.mainLayoutLifeSimplifiedView {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 0;
			gap: 2.4rem;
			width: 42rem;
		}

		.mainActionsContent {
			display: flex;
			flex-direction: column;
			border: 0.05rem solid $v3-primary-shadow-color-15;
			background: $secondary-color;
			border-radius: 1.2rem;
			align-self: stretch;
			align-items: stretch;
		}

		.mainActionsContent.skypeUnsupported {
			border: none;
			background: #edeced;
			.headerContainer {
				justify-content: left;
				height: 2.3rem;
				background: #edeced;
			}
			.contentContainer {
				padding: 1.2rem;
				align-items: flex-start;
				background: #edeced;
				.title {
					color: rgba(80, 89, 201, 1);
					font-size: 2.4rem;
					text-align: left;
					max-width: 36rem;
					margin-bottom: 12px;
				}
				.unsupportedInfo {
					display: block;
					margin-top: 15px;
					margin-bottom: 25px;
					color: black;
					font-size: 15px;
				}
				.description {
					font-size: 1.5rem;
					text-align: left;
					color: black;
				}
				#instructions {
					margin-top: 0.8rem;
				}

				#instructionsLearnMore {
					padding-top: 0.2rem;
					font-weight: 400;
					color: black;
					text-decoration: underline;
					margin-bottom: 4rem;
				}
			}
			.logoContainer {
				width: 4.145rem;
				height: 1.145rem;
				top: 0.4rem;
				box-shadow: none;
				border-radius: none;
				justify-content: none;
				background: none;

				.logo {
					width: 24px !important;
					height: 24px !important;
					align-self: center;
				}
			}
			.actionsContainer {
				background: #edeced;
			}
		}

		.banner {
			display: flex;
			flex-direction: column;
			min-height: 13rem;

			.illustration {
				border-radius: 1.2rem 1.2rem 0 0;
			}
		}

		.headerContainer {
			justify-content: center;
			display: flex;
			background: $secondary-color;

			height: 4.9rem;
			margin-bottom: 0;

			transition: height 0.4s ease-in;
		}

		.secondaryActionsContent {
			align-items: center;
			text-align: center;
			display: flex;
			flex-direction: column;
		}

		.teamsImg {
			width: 2.4rem;
			height: 2.4rem;
		}

		.contentContainer {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 3.2rem;
			background: $secondary-color;
			border-radius: 1.2rem;
			color: $v3-base-font-color;
			text-align: center;

			.title {
				max-width: 27rem;
				color: $v3-base-font-color-darker;
				font-size: 2rem;
				line-height: 2.8rem;
				font-weight: bold;
				padding-bottom: 0.375rem;
			}

			.description {
				font-size: 1.6rem;
				line-height: 2.2rem;
				font-weight: 400;
			}

			#addToCalendar {
				margin-top: 0.5rem;
				font-weight: 600;
			}

			p {
				margin: 0rem;
			}

			#instructions {
				margin-top: 0.8rem;
			}

			#instructionsLearnMore {
				padding-top: 1.5rem;
				font-weight: 400;
			}
		}

		.actionsContainer {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 0rem 3.2rem 3.2rem 3.2rem;
			gap: 3.2rem;
			background: $secondary-color;
			align-self: stretch;
			border-radius: 1.2rem;
		}

		.buttonsContainer {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 0;
			gap: 0.8rem;
			align-self: stretch;
		}

		.logoContainer {
			position: relative;
			width: 9.8rem;
			height: 9.8rem;
			top: -4.9rem;
			display: flex;
			justify-content: center;
			align-items: center;
			background: $secondary-color;
			box-shadow: 0 0 0.2rem $v3-primary-shadow-color-12,
				0 0.2rem 0.4rem $v3-primary-shadow-color-14;
			border-radius: 0.8rem;
			transition: opacity 0.2s 0.2s ease-in;

			.logo {
				width: 5.145rem;
				height: 5.145rem;
				align-self: center;
			}

			.logoCustom {
				border-radius: 0.8rem;
				align-self: stretch;
				width: 100%;
				height: 100%;
				outline: 1px solid rgba(255, 255, 255, 0.3);
				outline-offset: -1px;
			}
		}

		.logoText {
			text-align: left;
			font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
			font-size: 16px;
			font-weight: bold;
		}

		.secondaryAction {
			& .img {
				opacity: 1;
			}
		}
	}

	.skypeSupported {
		.modernLifeSimplifiedView {
			.mainActionsContent {
				border: none;
				.headerContainer {
					justify-content: left;
					height: 2.3rem;
				}
				.contentContainer {
					padding: 1.2rem;
					align-items: flex-start;
					.title {
						color: rgba(80, 89, 201, 1);
						font-size: 2.4rem;
						text-align: left;
						max-width: 36rem;
						margin-bottom: 12px;
					}
					.unsupportedInfo {
						display: block;
						margin-top: 15px;
						margin-bottom: 25px;
						color: black;
						font-size: 15px;
					}
					.description {
						font-size: 1.5rem;
						text-align: left;
						color: black;
					}
					#instructions {
						margin-top: 0.8rem;
					}

					#instructionsLearnMore {
						padding-top: 0.2rem;
						font-weight: 400;
						color: black;
						text-decoration: underline;
						margin-bottom: 4rem;
					}
				}
				.logoContainer {
					width: 4.145rem;
					height: 1.145rem;
					top: 0.4rem;
					box-shadow: none;
					border-radius: none;

					.logo {
						width: 24px !important;
						height: 24px !important;
						align-self: center;
					}
				}
			}
		}
	}

	.skypeUnsupported {
		.modernLifeSimplifiedView {
			.mainActionsContent {
				border: none;
				background: #edeced;
				.headerContainer {
					justify-content: left;
					height: 2.3rem;
					background: #edeced;
				}
				.contentContainer {
					padding: 1.2rem;
					align-items: flex-start;
					background: #edeced;
					.title {
						color: rgba(80, 89, 201, 1);
						font-size: 2.4rem;
						text-align: left;
						max-width: 36rem;
						margin-bottom: 12px;
					}
					.unsupportedInfo {
						display: block;
						margin-top: 15px;
						margin-bottom: 25px;
						color: black;
						font-size: 15px;
					}
					.description {
						font-size: 1.5rem;
						text-align: left;
						color: black;
					}
					#instructions {
						margin-top: 0.8rem;
					}

					#instructionsLearnMore {
						padding-top: 0.2rem;
						font-weight: 400;
						color: black;
						text-decoration: underline;
						margin-bottom: 4rem;
					}
				}
				.logoContainer {
					width: 4.145rem;
					height: 1.145rem;
					top: 0.4rem;
					box-shadow: none;
					border-radius: none;
					justify-content: none;
					background: none;

					.logo {
						width: 24px !important;
						height: 24px !important;
						align-self: center;
					}
				}
				.actionsContainer {
					background: #edeced;
				}
			}
		}
	}
}

@include responsiveMediaV3(big) {
	html.uiv3 {
		font-size: 10px; //calc(0.95em * 0.625);
	}
}

@include responsiveMediaV3(medium) {
	html.uiv3 {
		font-size: 9px; //calc(0.93em * 0.625);
	}
}

@include responsiveMediaV3(small) {
	html.uiv3 {
		font-size: 8px; //calc(0.91em * 0.625);
	}
}

// Styling for Spartan (Edge)
@supports (-ms-ime-align: auto) {
	html.uiv3 {
		.modernLifeSimplifiedView {
			> div:not(:last-child) {
				margin-bottom: 2.4rem;
			}
			.actionsContainer > div:not(:last-child) {
				margin-bottom: 3.2rem;
			}
			.buttonsContainer > div:not(:last-child) {
				margin-bottom: 0.8rem;
			}
		}
	}
}

@import "../common/utilities.scss";
@import "../common/colors.scss";
@import "../common/fontSizes.scss";

$layout-width: 28.6rem;
$image-width: 24rem;

.modernSimplifiedMobileView {
	max-width: $layout-width;
	margin: 0 auto;

	color: $font-color-mobile;

	@include responsiveMedia(big) {
		.spacer {
			flex: 0;
		}
	}

	.banner {
		display: flex;
		flex-direction: column;
		width: 110%;

		.bannerImg {
			border-radius: 1.2rem;
		}
	}

	.headerContainer {
		height: 4.9rem;

		.logoContainer {
			position: relative;
			width: 8rem;
			height: 8rem;
			top: -4rem;
			display: flex;
			justify-content: center;
			align-items: center;
			background: $secondary-color;
			box-shadow: 0 0 0.2rem $v3-primary-shadow-color-12,
				0 0.2rem 0.4rem $v3-primary-shadow-color-14;
			border-radius: 0.8rem;
			transition: opacity 0.2s 0.2s ease-in;

			.logo {
				width: 5.145rem;
				height: 5.145rem;
				align-self: center;
			}

			.logoCustom {
				border-radius: 0.8rem;
				align-self: stretch;
				width: 100%;
				height: 100%;
				outline: 1px solid rgba(255, 255, 255, 0.3);
				outline-offset: -1px;
			}
		}
	}

	.illustration {
		width: $image-width;
	}

	.contentContainer {
		display: flex;
		flex-direction: column;
		gap: 0.6rem;
		margin-top: 2rem;
		margin-bottom: 3rem;
		font-size: 1.2rem;
		line-height: 2rem;
		font-weight: 400;
		color: $font-color-mobile;

		p {
			margin: 0;
		}
		.title {
			font-size: 1.8rem;
			line-height: 2.4rem;
			font-weight: bold;
			color: black;
		}
		.description {
			font-size: 1.4rem;
			line-height: 2rem;
		}
	}

	.buttonsContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		gap: 1.2rem;
		padding: 0.875rem 1.25rem;

		button {
			margin: 0;
			max-width: none;
		}

		.viewDetails {
			@include app-font-h3-button();
			height: 4rem;
			display: flex;
			align-items: center;
		}
	}

	a {
		font-weight: normal;
	}

	.message {
		font-size: 1.2rem;
		@include app-font-base-mobile();
		color: $font-color-mobile;
	}

	.downloadApp {
		margin: 1.2rem 0;
		border-top: 0.1rem solid $secondary-border-color;
		padding-top: 1.2rem;
	}

	.hyperlink {
		@include app-font-small();
		color: $font-color-mobile;
		font-weight: 500;
		padding: 1.5rem 2.31rem;
	}

	.teamsImg {
		padding: 3rem;
		width: 2.4rem;
		height: 2.4rem;
	}

	.msteamsImg {
		width: 1.2rem !important;
		height: 1.2rem !important;
		margin-right: 0.2rem;
	}

	// Larger mobiles have larger image
	@media screen and (min-device-width: 400px) {
		.illustration {
			width: 28rem;
		}
	}

	.legalNotice {
		font-size: 0.8rem;
		opacity: 0.8;
		width: $layout-width;
	}

	.checkboxContainer {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		text-align: start;
		@include app-font-small();
		color: $font-color-mobile;
		font-weight: 400;
	}

	.checkbox {
		margin-right: 1.2rem;
		accent-color: $primary-color;
	}

	.modalDialogOuterContainer {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 1;
		background-color: $v2-primary-shadow-color; // Fallback background color
		background-color: $v2-primary-shadow-color-40;
	}

	.dialogBoxContainer {
		width: $image-width;
		color: $app-black;
		background-color: $v2-background-color;
		border-radius: 1rem;
		text-align: center;
		padding-top: 1.5rem;
	}

	.dialogBoxHeader {
		@include app-font-base-mobile();
		font-weight: 600;
		padding-bottom: 0.5rem;
	}

	.dialogBoxDetails {
		@include app-font-small();
		font-weight: 400;
		padding: 0 1rem 1.5rem 1rem;
	}

	.dialogBoxButtonsConatiner {
		display: flex;
		flex-direction: row;
		border: 0;
	}

	.dialogBoxButton {
		flex: 1;
		height: 3.5rem;
		background-color: $v2-background-color;
		padding-bottom: 1rem;
		border: 0;
		border-top: 0.1rem solid $secondary-border-color;
		color: $primary-color;
	}

	.dialogBoxContinueButton {
		border-bottom-left-radius: 1rem;
		font-weight: 400;
	}

	.dialogBoxCancelButton {
		border-bottom-right-radius: 1rem;
		border-left: 0.1rem solid $secondary-border-color;
		font-weight: 600;
	}

	.logoText {
		font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
		font-size: 10px;
	}

	.logoUnderFooter {
		display: flex;
		flex-direction: row;
		margin-top: 0.9rem;
	}
}

.skypeSupported {
	.modernSimplifiedMobileView {
		.contentContainer {
			padding: 1.2rem;
			.title {
				color: rgba(80, 89, 201, 1);
				font-size: 2rem;
				margin-bottom: 12px;
			}
			.description {
				font-size: 1.3rem;
				line-height: 2.2rem;
				font-weight: 400;
			}
			.learnMore {
				font-weight: 400;
				text-decoration: underline;
				margin-bottom: -2rem;
				color: black;
			}
		}
	}
}

.skypeUnsupported {
	.modernSimplifiedMobileView {
		.contentContainer {
			padding: 1.2rem;
			.title {
				color: rgba(80, 89, 201, 1);
				font-size: 1.9rem;
				margin-bottom: 12px;
			}
			.description {
				font-size: 1.2rem;
				line-height: 2.2rem;
				font-weight: 400;
			}
			.learnMore {
				font-weight: 400;
				text-decoration: underline;
				margin-bottom: -2rem;
				color: black;
			}
		}
	}
}

$app-black: #252423;

$primary-color: #5b5fc7;
$primary-color-hover: #444791;
$primary-color-focus: #2f2f4a;
$primary-border-color: transparent;

$secondary-color: white;
$secondary-color-hover: #edebe9;
$secondary-border-color: #e1dfdd;
$secondary-border-color-hover: #c8c6c4;

$anchor-color: #5b5fc7;

$font-color: #252424;
$font-color-mobile: #6f6d6d;
$background-color: #f3f2f1;

$v2-background-color: #f7f7f7;
$v2-lighter-background-color: #faf9f8;
$v2-lighter-font-color: #605e5c;
$v2-light-font-color: #979593;
$v2-primary-shadow-color: rgb(0, 0, 0);
$v2-primary-shadow-color-10: rgba(0, 0, 0, 0.1);
$v2-primary-shadow-color-11: rgba(0, 0, 0, 0.11);
$v2-primary-shadow-color-13: rgba(0, 0, 0, 0.13);
$v2-primary-shadow-color-40: rgba(0, 0, 0, 0.4);

/* Uiv3 */
$v3-body-background-color: #fafafa;
$v3-base-font-color: #616161;
$v3-base-font-color-darker: #242424;
$v3-ufd-background: #fffffff2;
$v3-primary-button-color: #6264a7;
$v3-primary-button-color-hover: #585a96ff;
$v3-primary-button-color-active: #464775ff;
$v3-secondary-button-color-active: #e0e0e0;
$v3-secondary-button-color-hover: #f5f5f5;
$v3-secondary-button-border: #c8c6c4ff;
$v3-secondary-button-color-font: #252423ff;
$v3-tooltip-border-color: #e1e1e1;
$v3-primary-shadow-color-06: rgba(0, 0, 0, 0.06);
$v3-primary-shadow-color-10: rgba(0, 0, 0, 0.1);
$v3-primary-shadow-color-12: rgba(0, 0, 0, 0.12);
$v3-primary-shadow-color-14: rgba(0, 0, 0, 0.14);
$v3-primary-shadow-color-15: rgba(0, 0, 0, 0.15);
$v3-primary-shadow-color-25: rgba(0, 0, 0, 0.25);
